import React from 'react'
import wpp from '../images/wpp.svg'

import styles from './contato.module.css'

function Contato () {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Entre em contato com a produção de</h1>
      <h1 className={styles.title}>As Ganhadeiras de Itapuã</h1>
      <div className={styles.content}>
        <div className={styles.wpp}>
          <a className={styles.wpplink}
            href="https://api.whatsapp.com/send?phone=557199588672&text=Ol%C3%A1!%20Estou%20entrando%20em%20contato%20com%20a%20produ%C3%A7%C3%A3o%20do%20grupo%20As%20Ganhadeiras%20de%20Itapu%C3%A3"
            target="_blank"
          >
            <button className={styles.wppbutton}>
              <img src={wpp} alt="Logo Whatsapp" />
              <p>Contato via Whatsapp</p>
            </button>
          </a>
        </div>

        <div className={styles.form}>
          <p>Via e-mail</p>
          <form name="contato" id="contato" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/sucesso">
            <input type="hidden" name="form-name" value="contato"/>

            <div className={styles.formitem}>
              <label for="nome">Nome</label>
              <input type="text" id="nome" name="nome" placeholder="Seu nome" required/>
            </div>

            <div className={styles.formitem}>
              <label for="email">E-mail</label>
              <input type="email" id="email" name="email" placeholder="seu@email.com.br" required/>
            </div>

            <div className={styles.formitem}>
              <label for="telefone">Telefone</label>
              <input type="tel" id="telefone" name="telefone" placeholder="(DDD) + Seu número de telefone" />
            </div>

            <div className={styles.formitem}>
              <label for="mensagem">Mensagem</label>
              <textarea form="contato" id="mensagem" name="mensagem" placeholder="Sua mensagem" required></textarea>
            </div>

            <input type="submit" value="Enviar"/>
          </form>
        </div>
        
      </div>
    </div>
  )
}

export default Contato
